html,
body,
#root {
  font: 0.9rem sans-serif;
  background: #0a1f44;
  color: #1e2432;
  height: 100%;
  margin: 0;
}

.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

#menu-board {
  float: left;
  width: 50%;
}

#window-board {
  float: left;
  width: 50%;
}

@font-face {
  font-family: 'Prompt-Bold';
  src: local('Prompt-Bold'), url(./fonts/Prompt-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Prompt-Regular';
  src: local('Prompt-Regular'),
    url(./fonts/Prompt-Regular.ttf) format('truetype');
}
